(function( ){

//------------------------------------------------------------------------------------------------------------------------------

var Main = function(){

  loading();
  confetti();
  
  //------------------------------------------------------------------------------------------------------------------------------

  var target = document.getElementsByClassName("js-show-up") ;
  ScrollEnterEvent.add(target, { onEnter:function(evt){
      evt.target.className = evt.target.className + " is-animated" ;
    }, marginScale: 0.4}) ;
  }

  //------------------------------------------------------------------------------------------------------------------------------

  var loading = function() {
    var $img = $("img");
    var len = $img.length;
    var count = 0;
    var loadingObj = $('.loading');
    var loadedImgRatio = 0;
    var percentTxt = $('.progressnum');
    var pBar = $('.progressbar');

    $img.each(function(index, el){
      var img = new Image();
      var src = $(el).attr("src");
      img.onload = function() {
        count++;
      };
      img.src = src;
    });
    

    var timer = setInterval(function(){

      //%変換
      loadedImgRatio = (count / len) * 100;
      var loadedImgPer = Math.ceil(loadedImgRatio);

      //バー処理
      percentTxt.text(loadedImgPer + '%');
			pBar.css({'width': loadedImgPer + '%'});
      
      //終了処理
			if(loadedImgPer == 100){
        clearInterval(timer);
        loadingObj.delay(200).animate({
            'opacity': 0
          }, 400, function(){
            loadingObj.remove();
						// splashYoutube();
          });

        //kv animation 開始
        var kv = document.querySelector('.kv');
        kv.classList.add('is-animated');

        //アンカースクロールのズレ修正
        anchorScrollForTablets();

			}
		}, 5);

  }

//------------------------------------------------------------------------------------------------------------------------------

var DVD_DATE = document.querySelector('.kv__titleContainer');
var serverTime = 0;
var sDay = '2021:3:1:12:0:0';
var eDay = '2021:5:27:23:59:59';

new ServerTime(function(evt) {
  if (/localtime/.test(location.search)) {
    serverTime = new Date().getTime();
  } else {
    serverTime = evt.date.getTime();
  }
  const spltST = sDay.split(':');
  const spltET = eDay.split(':');

  const startD = new Date(spltST[0], spltST[1] - 1, spltST[2], spltST[3], spltST[4], spltST[5]).getTime();
  const endD = new Date(spltET[0], spltET[1] - 1, spltET[2], spltET[3], spltET[4], spltET[5]).getTime();

  // 開催中
  if (startD <= serverTime && endD >= serverTime) {
    DVD_DATE.classList.add('reserve');
  }
  // 開始日前
  else if (startD > serverTime) {
    DVD_DATE.classList.add('reserve');
  }
  //終了日後
  else {
    DVD_DATE.classList.add('sale');
  }
});

//------------------------------------------------------------------------------------------------------------------------------

  var anchorScrollForTablets = function() {
    var hashTxt = location.hash;
    if(hashTxt){
      hashTxt = hashTxt.replace( /#/g , '' );
      setTimeout(function(){
          var targetObj= document.getElementById(hashTxt) ;
          var targetOffset = (targetObj.offsetTop) * window.Fit.zoomScale;
          Tween.scroll( targetOffset , 0.8 , "easeInOutQuint" );
      }, 500)
    }
  }

  //------------------------------------------------------------------------------------------------------------------------------

  //splash movie

  // var player;
  // var ytStatus;
  // var fadeSpeed = 400;
  // var id = 'gfNMpsYtohU';

  // var splashYoutube = function() {
  //   onsplashYouTubePlayerAPIReady(id);

  //   $('.js-splashYoutubeClose, .js-splashYoutubeContents').on('click', function() {
  //     closeSplash();
  //   });

  //   $('.js-splashYoutubeWrapper').on('click', function(e) {
  //     e.stopPropagation();
  //   });

  // }

  // function closeSplash() {
  //   $('.js-splashYoutubeContents').fadeOut(fadeSpeed, function() {
  //     player.stopVideo();
  //     $('.js-splashYoutubeWrapper').empty();
  //     $('.js-splashYoutubeWrapper').append('<div id="js-splashYoutubeMovie"></div>');
  //   });
  // }

  // function onsplashYouTubePlayerAPIReady(id) {
  //   player = new YT.Player('js-splashYoutubeMovie', {
  //     height: '360',
  //     width: '640',
  //     videoId: id,
  //     autoplay: 1,
  //     rel: 0,
  //     playerVars: {
  //       playsinline: 1
  //     },
  //     events: {
  //       'onReady': firstVisit,//セッションに1回の場合
  //       // 'onReady': onPlayerReady,
  //       'onStateChange': onPlayerStateChange
  //     }
  //   });
  // }

  // function firstVisit(e) {
  //   if(sessionStorage.getItem('acss') === null) {
  //     sessionStorage.setItem('acss', 'on');
  //     onPlayerReady(e);
  //   }
  // }

  // function onPlayerReady(event) {
  //   $('.js-splashYoutubeContents').fadeIn(fadeSpeed);
  //   var myEle = event.target;
  //   myEle.playVideo();
  //   myEle.mute();
  //   ytStatus = myEle.getPlayerState();
  // }

  // function onPlayerStateChange(event) {
  //   ytStatus = event.target.getPlayerState();
  //   if (ytStatus == YT.PlayerState.ENDED) {
  //     closeSplash();
  //   }
  // }

//------------------------------------------------------------------------------------------------------------------------------
//スライドメニュー

var menu_btn = $('.js-top-menu__btn');
var contentsAll = $('.js-contents');
var menuAll = $('.js-top-menu__container');
var naviSlider = $('.js-naviSlider');

//fitjs対策
naviSlider.css({
  'pointer-events':'auto',
  'width': '1px',
  'min-width': '1px',
  'overflow': 'visible',
  'z-index': 0
});

menu_btn.on('click', function(){
  contentsAll.toggleClass('is-navi-active');
  menuAll.toggleClass('is-navi-active');
})

//------------------------------------------------------------------------------------------------------------------------------

  /***************************************************
  紙吹雪
  ***/

var confetti = function(){

  var spScale = .5;
  var pcScale = 1;
  var spParticleNum = 25;
  var pcParticleNum = 40;
  var confettiScale;//幅によって紙吹雪の幅に比率を付与]
  var confettiItems = [];
  var minWidth = 4;
  var maxWidth = 20;
  var minBorderW = 4;
  var maxBorderW = 10;
  var minOpacity = .5;
  var maxOpacity = 1;
  var maxBlur = 2;
  var tmpLeft;//X座標
  var tmpOpacity;//透明度
  var tmpSpeed;//落ちるまでにかかる秒数
  var maxSpeed = 30;//落ちる最おそ
  var minSpeed = 10;//落ちる最はや
  var tmpDelay;//始動タイミング遅らせる秒数
  var tmpBlur;
  var particleNum;//紙吹雪の数
  var confObj = $('.tp-kv_confetti');
  var widthToShift = 737;

  confettiInit();
  $(window).on('resize', sizeAdjust);
  
  function sizeAdjust(){
    var ws = $(window).width();
    if(ws < widthToShift && confettiScale === pcScale){//sp
      confettiScale = spScale;
      particleNum = spParticleNum;
      generateConfetti(confettiScale, particleNum);
    }else if(ws >= widthToShift && confettiScale === spScale){//pc
      confettiScale = pcScale;
      particleNum = pcParticleNum;
      generateConfetti(confettiScale, particleNum);
    }
  }

  function confettiInit() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
    //IE11では挙動が不安定なため降らせない
    } else {
      var ws = $(window).width();
      if(ws < widthToShift){//sp
        confettiScale = spScale;
        particleNum = spParticleNum;
      }else if(ws >= widthToShift){//pc
        confettiScale = pcScale;
        particleNum = pcParticleNum;
      }
      generateConfetti(confettiScale, particleNum);
    }
  }

  function generateConfetti(cs, pn) {

    confObj.children('div').remove();
  
    for (var i = 0; i < pn; i++) {

      var htmlObj = '<div class="tp-kv_confetti_item"><div class="tp-kv_confetti_item-ele"></div></div>';

      confettiItems.push($(htmlObj));

      //固有のcss属性生成
      tmpWidth = Math.floor((Math.random() * (maxWidth - minWidth) + minWidth) * 100) / 100;
      tmpBorderW = Math.floor(Math.random() * (maxBorderW - minBorderW) + minBorderW);
      tmpLeft = Math.floor(Math.random() * (100 - 0) + 0);
      tmpOpacity = Math.floor((Math.random() * (maxOpacity - minOpacity) + minOpacity) * 100) / 100;
      tmpSpeed = Math.floor(Math.random() * (maxSpeed - minSpeed) + minSpeed);
      tmpDelay = Math.floor(Math.random() * maxSpeed);
      tmpBlur = Math.random() * maxBlur;
      tmpY = Math.random() * 100;

      //縦の動き適用
      confettiItems[i].css({
        'top' : tmpY * -1 +'px'
        ,'left': tmpLeft + '%'
        ,'animation': 'snowfallVertical ' + tmpSpeed + 's linear infinite'
        ,'animation-delay': -1 * tmpDelay + 's'
      })

      //横
      confettiItems[i].find('.tp-kv_confetti_item-ele').css({
        'opacity' : tmpOpacity
        ,'animation': 'snowfallHorizontal ' + tmpSpeed + 's ease infinite'// スピード
        ,'animation-delay': -1 * tmpDelay + 's'
        ,'border-right': + (tmpBorderW * cs) +'px solid transparent'
        ,'border-bottom': + ((tmpBorderW * 1.73) * cs) +'px solid #fff'
        ,'border-left': + (tmpBorderW * cs) +'px solid transparent'
        ,'filter': 'blur(' + tmpBlur + 'px)'
      })

      confettiItems[i].prependTo(confObj);

    }

  }

}

//------------------------------------------------------------------------------------------------------------------------------


  window.requestAnimationFrame =  window.requestAnimationFrame ||
                  window.webkitRequestAnimationFrame ||
                  function( func ){ return setTimeout( func , 1000/60 ) ; } ;


  window.cancelAnimationFrame =   window.cancelAnimationFrame ||
                  window.webkitCancelAnimationFrame ||
                  function( id ){ clearTimeout( id ) ; } ;


  document.readyState === "complete"
    ? setTimeout( function(){ new Main( ) ; } , 1 )
    : document.addEventListener( "DOMContentLoaded", function(){ new Main( ) ; } ) ;


//------------------------------------------------------------------------------------------------------------------------------


} )( ) ;

